import "./App.css";

import { useEffect, useState } from "react";
import { init, track as ampTrack } from "@amplitude/analytics-browser";
import ImageGallery from "react-image-gallery";

export const downloadMobileApp = (platform, source) => {
  if (platform === "ios") {
    window.open("https://link.protoworld.io/ios-web", "_blank");
  } else if (platform === "android") {
    window.open("https://link.protoworld.io/android-web", "_blank");
  }
  track("DownloadMobileApp_Open", { platform, source });
  twitterDownloadEvent(platform);
};

const initAmplitude = () => {
  const isProduction = process.env.NODE_ENV === "production";
  if (isProduction) init("b7020f1132d7630370706f58642e3b89");
  else init("7f434ec60def3045fb9d90c1ff75d284");
};

var tryCount = 0;
var tracked = false;

const images = [
  {
    original: "/img/Download01.webp",
  },
  {
    original: "/img/Download02.webp",
  },
  {
    original: "/img/Download03.webp",
  },
  {
    original: "/img/Download04.webp",
  },
];

const track = (event, props) => {
  ampTrack(event, props);
  console.log("track", event, props);
};

const twitterDownloadEvent = (platform) => {
  if(!window.twq){
    console.log("twq not loaded");
    return;
  }
  window.twq('event', 'tw-ofxwv-ofxx3', {
    value: platform // use this to pass the value of the conversion (e.g. 5.00)
  });
  console.log("twq tracked");
}

const App = () => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    initAmplitude();
    track("DownloadMobileApp_Init");
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("source");
    if (!tracked ) {
      track("DownloadMobileApp_PageLoad", { source: myParam });
      tracked = true;
    }
    setSource(myParam);
  }, []);
  



  return (
    <div className=" fixed w-screen h-screen  overflow-y-scroll ">
      <div className="relative  w-full md:w-128 flex flex-col items-center justify-center rounded-xl py-5 md:py-10 ">
        <div
          className="w-full md:w-96 flex items-center justify-center py-5"
          // style={{ maxHeight: "50vh" }}
        >
          {/* <img
            src="/img/onboarding_5.webp"
            alt=""
            className="max-w-full max-h-full"
          /> */}

          <ImageGallery
            items={images}
            showBullets={true}
            showNav={false}
            showPlayButton={false}
            showFullscreenButton={false}
            autoPlay={true}
            sizes="(max-width: 640px) 100vw, 640px"
            
          />
        </div>
        <img
          src="/img/title.webp"
          className="w-10/12  color-white md:w-96"
          alt=""
        />
        <div className="flex flex-col mb-2">
          <a
            onClick={() => downloadMobileApp("ios", source)}
            className="p-1 w-36 cursor-pointer"
            target="_blank"
          >
            <img src="/img/app-ios.png" alt="IOS App download" />
          </a>
          <a
            onClick={() => downloadMobileApp("android", source)}
            className="p-1 w-36 cursor-pointer"
            target="_blank"
          >
            <img src="/img/app-google.png" alt="Android app download" />
          </a>
        </div>

        {/* Learn more */}
        <a
          onClick={() => {
            track("DownloadMobileApp_LearnMore", {});
            window.open("https://protoworld.io", "_self");
          }}
          className="opacity-70 text-white cursor-pointer"
        >
          Learn More
        </a>
        <img src="/img/logo-icon.svg" className="w-20 mt-10 mb-4 " alt="" />

      </div>
    </div>
  );
};

export default App;
